import { render, staticRenderFns } from "./publish_method.vue?vue&type=template&id=3dc9aa61"
import script from "./publish_method.vue?vue&type=script&lang=js"
export * from "./publish_method.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports